import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, Fragment, useCallback, useContext, useMemo } from "react";

import RequestContext from "pages/RequestContext";

import getDeviceFromUserAgent from "utils/getDeviceFromUserAgent";
import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  list: {
    clear: "both",
  },
  item: {
    width: "25%",
    float: "left",
    textAlign: "center",
    lineHeight: 1.2,
    marginBottom: "1.5rem",
    position: "relative",

    [ScreenSizes.xsAndBelow]: {
      minHeight: 89,
    },
  },
  lastItem: {
    clear: "both",
  },
  link: {
    display: "block",
    cursor: "pointer",
  },
  imageContainer: {
    display: "block",
    width: "100%",
    backgroundPosition: "center center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    height: 53,
    position: "relative",
    overflow: "hidden",
    marginBottom: "0.5rem",
  },
  image: {
    position: "absolute",
    width: "100%",
    height: "100%",
    opacity: 0,
    left: 0,
    top: 0,
  },
  title: {
    ...gStyles.fontSemiBold,
    display: "block",
    width: "100%",
    color: colours.bodyText,
    fontSize: 11,
  },
  badgeContainer: {
    position: "absolute",
    left: 0,
    width: "100%",
    marginTop: "-0.25rem",
  },
  badge: {
    ...gStyles.fontSemiBold,
    display: "inline-block",
    background: colours.primary,
    color: colours.white,
    borderRadius: "4rem",
    fontSize: 8,
    padding: "0.3em 0.7em 0.2em",
    textTransform: "uppercase",
    lineHeight: 1,
  },
};

const ExternalPlayerLinks = (props) => {
  const { items, onClick, entity_id, entity_type, titleTemplate } = props;

  const requestContext = useContext(RequestContext);

  const isValidForDevice = useCallback(
    (item) =>
      item.devices.includes(getDeviceFromUserAgent(requestContext.userAgent)),
    [requestContext.userAgent]
  );

  const filteredItems = useMemo(
    () => items.filter(isValidForDevice),
    [isValidForDevice, items]
  );

  const imageStyles = useMemo(
    () =>
      filteredItems.reduce((imageStyles, item) => {
        imageStyles[`imageContainer${item.id}`] = {
          backgroundImage: `url(${item.image})`,
        };
        return imageStyles;
      }, []),
    [filteredItems]
  );

  const { styles } = useStyles([baseStyles, imageStyles], props);

  const handleClick = useCallback(
    (item) => (e) => {
      if (item.action) {
        item.action();
        e.preventDefault();
      }

      sendGAEvent({
        action: "externalPlayerLinkClicked",
        key: item.id,
        entity_id,
        entity_type,
      });

      if (onClick) {
        onClick({ e });
      }
      if (item.onClick) {
        item.onClick();
      }
    },
    [onClick, entity_type, entity_id]
  );

  const renderItemContent = (item) => (
    <Fragment>
      <span
        className={css(
          styles.imageContainer,
          styles[`imageContainer${item.id}`]
        )}
      >
        <img
          className={css(styles.image)}
          src={item.image}
          alt={item.title}
          title={
            titleTemplate
              ? titleTemplate.replace("[player]", item.title)
              : item.title
          }
        />
      </span>
      <span className={css(styles.title)}>{item.title}</span>
      {item.badge && (
        <span className={css(styles.badgeContainer)}>
          <span className={css(styles.badge)}>{item.badge.text}</span>
        </span>
      )}
    </Fragment>
  );

  const renderItem = (item) => {
    const title = titleTemplate
      ? titleTemplate.replace("[player]", item.title)
      : item.title;

    if (item.link) {
      return (
        <a
          className={css(styles.link)}
          target="_blank"
          rel="noopener noreferrer"
          href={item.link}
          onClick={handleClick(item)}
          title={title}
        >
          {renderItemContent(item)}
        </a>
      );
    }

    return (
      <div
        className={css(styles.link)}
        onClick={handleClick(item)}
        title={title}
      >
        {renderItemContent(item)}
      </div>
    );
  };

  return (
    <ul className={css(styles.list)}>
      {filteredItems.map((item) => (
        <li className={css(styles.item)} key={item.id}>
          {renderItem(item)}
        </li>
      ))}
      <li key="last-player-link" className={css(styles.lastItem)} />
    </ul>
  );
};

ExternalPlayerLinks.propTypes = {
  items: PropTypes.array.isRequired,
  entity_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  entity_type: PropTypes.string.isRequired,
  titleTemplate: PropTypes.string,
  onClick: PropTypes.func,
};

ExternalPlayerLinks.defaultProps = {
  titleTemplate: null,
};

export default memo(ExternalPlayerLinks);
