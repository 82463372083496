import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";

import LoadingOverlay from "components/Common/LoadingOverlay";

import ExternalPlayerLinks from "../ExternalPlayerLinks";

import podcastActions from "actions/podcast";
import {
  selectSpecificEpisode,
  selectEpisodeLoading,
  selectEpisodeFailed,
} from "selectors/podcast";
import { getEpisodeExternalPlayerLink, PLAYERS } from "utils/externalPlayers";

import useActionCreators from "hooks/useActionCreators";
import useAudioPlayerContext from "hooks/useAudioPlayerContext";
import useLoadEntity from "hooks/useLoadEntity";

const loadingStyles = {
  overlay: {
    width: "31rem",
    maxWidth: "100%",
  },
};

const EpisodeExternalPlayerLinks = (props) => {
  const { podcast, episode: passedEpisode } = props;
  const { playEpisode } = useAudioPlayerContext();

  const { loadSpecificEpisode } = useActionCreators({
    loadSpecificEpisode: podcastActions.loadSpecificEpisode,
  });

  const { entity: episode, loading } = useLoadEntity({
    entity_type: "user_by_username",
    entity_id: passedEpisode && passedEpisode.get("id"),
    reloadTest: (entity) => !entity.get("full_record"),
    loader: loadSpecificEpisode,
    selector: selectSpecificEpisode,
    loadingSelector: selectEpisodeLoading,
    failedSelector: selectEpisodeFailed,
  });

  const items = useMemo(
    () =>
      [
        {
          action: () => playEpisode(episode.get("id")),
          image: "/images/players/logo-podchaser.png",
          title: "Podchaser",
          ...PLAYERS.podchaser,
        },
        episode.get("guid") &&
          podcast.get("itunes_id") && {
            link: getEpisodeExternalPlayerLink(
              podcast,
              episode,
              PLAYERS.podcast_guru.id
            ),
            image: "/images/players/logo-podcast-guru.png",
            title: "Podcast Guru",
            ...PLAYERS.podcast_guru,
          },
        episode.get("guid") && {
          link: getEpisodeExternalPlayerLink(
            podcast,
            episode,
            PLAYERS.radiopublic.id
          ),
          image: "/images/players/logo-radiopublic.png",
          title: "RadioPublic",
          ...PLAYERS.radiopublic,
        },
        (episode.get("guid") || episode.get("audio_url")) && {
          link: getEpisodeExternalPlayerLink(
            podcast,
            episode,
            PLAYERS.playerfm.id
          ),
          image: "/images/players/logo-playerfm.png",
          title: "Player FM",
          ...PLAYERS.playerfm,
        },
        episode.hasIn(["meta_data", "external_ids", "spotify"]) &&
          episode.getIn(["meta_data", "external_ids", "spotify"]) !==
            "NOT_FOUND" && {
            link: getEpisodeExternalPlayerLink(
              podcast,
              episode,
              PLAYERS.spotify.id
            ),
            image: "/images/players/logo-spotify.png",
            title: "Spotify",
            ...PLAYERS.spotify,
          },
        episode.get("guid") && {
          link: getEpisodeExternalPlayerLink(
            podcast,
            episode,
            PLAYERS.google_podcasts.id
          ),
          image: "/images/players/logo-google-podcasts.png",
          title: "Google Podcasts",
          ...PLAYERS.google_podcasts,
        },
        episode.get("guid") && {
          link: getEpisodeExternalPlayerLink(
            podcast,
            episode,
            PLAYERS.podbean.id
          ),
          image: "/images/players/logo-podbean.png",
          title: "Podbean",
          ...PLAYERS.podbean,
        },
        episode.get("guid") && {
          link: getEpisodeExternalPlayerLink(
            podcast,
            episode,
            PLAYERS.procast.id
          ),
          image: "/images/players/logo-procast.png",
          title: "Procast",
          ...PLAYERS.procast,
        },
        episode.get("guid") && {
          link: getEpisodeExternalPlayerLink(
            podcast,
            episode,
            PLAYERS.castbox.id
          ),
          image: "/images/players/logo-castbox.png",
          title: "Castbox",
          ...PLAYERS.castbox,
        },
        episode.get("guid") && {
          link: getEpisodeExternalPlayerLink(
            podcast,
            episode,
            PLAYERS.bullhorn.id
          ),
          image: "/images/players/logo-bullhorn.png",
          title: "Bullhorn",
          ...PLAYERS.bullhorn,
        },
        episode.get("guid") && {
          link: getEpisodeExternalPlayerLink(
            podcast,
            episode,
            PLAYERS.podhero.id
          ),
          image: "/images/players/logo-podhero.png",
          title: "Podhero",
          ...PLAYERS.podhero,
        },
        {
          link: getEpisodeExternalPlayerLink(podcast, episode, PLAYERS.lstn.id),
          image: "/images/players/logo-lstn.png",
          title: "LSTN",
          badge: {
            text: "New",
          },
          ...PLAYERS.lstn,
        },
        episode.get("guid") && {
          link: getEpisodeExternalPlayerLink(
            podcast,
            episode,
            PLAYERS.podcastgo.id
          ),
          image: "/images/players/logo-podcastgo.png",
          title: "PodcastGo",
          badge: {
            text: "New",
          },
          ...PLAYERS.podcastgo,
        },
      ].filter((i) => !!i),
    [episode, podcast, playEpisode]
  );

  if (!episode || loading) {
    return <LoadingOverlay styles={loadingStyles} />;
  }

  return (
    <ExternalPlayerLinks
      items={items}
      titleTemplate={`${episode.get("title")} on [player]`}
      entity_id={episode.get("id")}
      entity_type="episode"
      {...props}
    />
  );
};

EpisodeExternalPlayerLinks.propTypes = {
  podcast: PropTypes.instanceOf(Map).isRequired,
  episode: PropTypes.instanceOf(Map).isRequired,
};

export default memo(EpisodeExternalPlayerLinks);
