const expectedExpression = /Safari/i;

function isMac() {
  const userAgent = window?.navigator?.userAgent || "";

  return /Macintosh/i.test(userAgent) && expectedExpression.test(userAgent);
}

function isIphoneOrIpad() {
  const userAgent = window?.navigator?.userAgent || "";

  return /iPhone|Ipad/i.test(userAgent) && expectedExpression.test(userAgent);
}

export { isMac, isIphoneOrIpad };
