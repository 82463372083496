import btoa from "btoa";
import sha1 from "sha1";

import { doubleURIEncode } from "./misc";

export const PLAYERS = {
  podchaser: { id: "podchaser", devices: ["web", "ios", "android"] },
  radiopublic: { id: "radiopublic", devices: ["web", "ios", "android"] },
  playerfm: { id: "playerfm", devices: ["web", "ios", "android"] },
  apple_podcasts: { id: "apple_podcasts", devices: ["web", "ios"] },
  pocket_casts: { id: "pocket_casts", devices: ["web", "ios", "android"] },
  overcast: { id: "overcast", devices: ["web", "ios"] },
  castro: { id: "castro", devices: ["web", "ios", "android"] },
  procast: { id: "procast", devices: ["ios"] },
  podbean: { id: "podbean", devices: ["web", "ios", "android"] },
  google_podcasts: { id: "google_podcasts", devices: ["android"] },
  castbox: { id: "castbox", devices: ["web", "ios", "android"] },
  bullhorn: { id: "bullhorn", devices: ["web", "ios", "android"] },
  spotify: { id: "spotify", devices: ["web", "ios", "android"] },
  pandora: { id: "pandora", devices: ["web", "ios", "android"] },
  podhero: { id: "podhero", devices: ["web", "ios", "android"] },
  podcast_guru: { id: "podcast_guru", devices: ["web", "android"] },
  lstn: { id: "lstn", devices: ["ios"] },
  podcastgo: { id: "podcastgo", devices: ["android"] },
};

export function getPodcastExternalPlayerLink(podcast, player) {
  switch (player) {
    case PLAYERS.radiopublic.id:
      return `https://play.radiopublic.com/${encodeURIComponent(
        podcast.get("feed_url")
      )}`;
    case PLAYERS.pocket_casts.id:
      return `http://pca.st/itunes/${podcast.get("itunes_id")}`;
    case PLAYERS.apple_podcasts.id:
      return `https://itunes.apple.com/podcast/id${podcast.get(
        "itunes_id"
      )}?mt=2&ls=1`;
    case PLAYERS.overcast.id:
      return `https://overcast.fm/itunes${podcast.get("itunes_id")}`;
    case PLAYERS.castro.id:
      return `https://castro.fm/itunes/${podcast.get("itunes_id")}`;
    case PLAYERS.playerfm.id:
      return `https://player.fm/series/${doubleURIEncode(
        podcast.get("feed_url")
      )}`;
    case PLAYERS.procast.id:
      return `procast://se/${podcast.get("itunes_id")}`;
    case PLAYERS.podbean.id:
      return `https://www.podbean.com/play/${doubleURIEncode(
        podcast.get("feed_url")
      )}`;
    case PLAYERS.google_podcasts.id:
      return `https://www.google.com/podcasts?feed=${encodeURIComponent(
        btoa(podcast.get("feed_url"))
      )}`;
    case PLAYERS.castbox.id:
      return `http://castbox.fm/podchaser/feed/${doubleURIEncode(
        podcast.get("feed_url")
      )}`;
    case PLAYERS.bullhorn.id:
      if (podcast.has("itunes_id")) {
        return `https://www.bullhorn.fm/podchaser/itunes/${podcast.get(
          "itunes_id"
        )}`;
      }
      return `https://www.bullhorn.fm/podchaser/feed/${encodeURIComponent(
        podcast.get("feed_url")
      ).replace(/\//g, "%2F")}`;
    case PLAYERS.spotify.id:
      return podcast.getIn(["external_ids", "spotify"])
        ? `https://open.spotify.com/show/${podcast.getIn([
            "external_ids",
            "spotify",
          ])}`
        : null;
    case PLAYERS.pandora.id:
      return `https://pandora.com/podcast/${podcast.get(
        "slug"
      )}/${podcast.getIn(["external_ids", "pandora", "id"])}`;
    case PLAYERS.podcast_guru.id:
      return `https://app.podcastguru.io/podcast/${podcast.get("itunes_id")}`;
    case PLAYERS.podhero.id:
      return `https://podhero.com/podcast/feed/${encodeURIComponent(
        podcast.get("feed_url")
      )}`;
    case PLAYERS.lstn.id:
      return `https://lstn.now.sh/podcast/${podcast.get("id")}`;
    case PLAYERS.podcastgo.id:
      return `https://podcastgo.pl/listen?rss=${encodeURIComponent(
        podcast.get("feed_url")
      )}`;
    default:
      return "";
  }
}

export function getEpisodeExternalPlayerLink(podcast, episode, player) {
  switch (player) {
    case PLAYERS.radiopublic.id:
      return `https://play.radiopublic.com/${encodeURIComponent(
        podcast.get("feed_url")
      )}/ep/s1!${sha1(episode.get("guid"))}`;
    case PLAYERS.playerfm.id: {
      const episodeID = episode.has("guid")
        ? `guid:${doubleURIEncode(episode.get("guid"))}`
        : `${doubleURIEncode(episode.get("audio_url"))}`;
      return `https://player.fm/series/${doubleURIEncode(
        podcast.get("feed_url")
      )}/${episodeID}`;
    }
    case PLAYERS.procast.id:
      return `procast://se/${podcast.get("itunes_id")}/${encodeURIComponent(
        episode.get("guid")
      )}`;
    case PLAYERS.podbean.id:
      return `https://www.podbean.com/play/${doubleURIEncode(
        podcast.get("feed_url")
      )}/ep/${doubleURIEncode(episode.get("guid"))}`;
    case PLAYERS.google_podcasts.id:
      return `https://www.google.com/podcasts?feed=${encodeURIComponent(
        btoa(podcast.get("feed_url"))
      )}&episode=${btoa(episode.get("guid"))}`;
    case PLAYERS.castbox.id:
      return `http://castbox.fm/podchaser/feed/${doubleURIEncode(
        podcast.get("feed_url")
      )}/ep/${doubleURIEncode(episode.get("guid"))}`;
    case PLAYERS.bullhorn.id:
      return `https://www.bullhorn.fm/podchaser/feed/${encodeURIComponent(
        podcast.get("feed_url")
      ).replace(/\//g, "%2F")}/ep/${encodeURIComponent(episode.get("guid"))}`;
    case PLAYERS.spotify.id:
      return episode.getIn(["meta_data", "external_ids", "spotify"])
        ? `https://open.spotify.com/episode/${episode.getIn([
            "meta_data",
            "external_ids",
            "spotify",
          ])}`
        : null;
    case PLAYERS.podcast_guru.id:
      return `https://app.podcastguru.io/podcast/${podcast.get(
        "itunes_id"
      )}/episode/${episode.get("id")}`;
    case PLAYERS.podhero.id:
      return `https://podhero.com/podcast/feed/${encodeURIComponent(
        podcast.get("feed_url")
      )}/guid/${encodeURIComponent(episode.get("guid"))}`;
    case PLAYERS.lstn.id:
      return `https://lstn.now.sh/episode/${episode.get("id")}`;
    case PLAYERS.podcastgo.id:
      return `https://podcastgo.pl/listen?rss=${encodeURIComponent(
        podcast.get("feed_url")
      )}&guid=${encodeURIComponent(episode.get("guid"))}`;
    default:
      return "";
  }
}
