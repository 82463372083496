/* eslint-disable react/display-name */
import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";
import { setBookmarkedPromise } from "routines/bookmarks";
import { setListenedPromise } from "routines/listens";

import EllipsisMenuAddCreditsItem from "components/Buttons/EllipsisMenuButton/EllipsisMenuAddCreditsItem";
import EllipsisMenuBackItem from "components/Buttons/EllipsisMenuButton/EllipsisMenuBackItem";
import EllipsisMenuButton from "components/Buttons/EllipsisMenuButton/EllipsisMenuButton";
import EllipsisMenuItem from "components/Buttons/EllipsisMenuButton/EllipsisMenuItem";
import PlayButton from "components/Buttons/PlayButton";
import EpisodeExternalPlayerLinks from "components/Podcast/Episode/EpisodeExternalPlayerLinks";
import EpisodeSharingLinks from "components/Podcast/Episode/EpisodeSharingLinks";

import modalActions from "actions/modals";
import ratingActionCreators from "actions/rating";
import { podcastExclusiveTo } from "utils/entity/exclusiveTo";
import getEpisodeUrl from "utils/entity/getEpisodeUrl";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useActivityContext from "hooks/useActivityContext";
import useRoutinePromises from "hooks/useRoutinePromises";
import { useStyles } from "hooks/useStyles";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  column: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
};

const sharingLinkStyles = {
  list: {
    fontSize: "2rem",
  },
  item: {
    maxWidth: "3.5rem",
    minWidth: "3.5rem",

    [ScreenSizes.lgAndAbove]: {
      maxWidth: "3.5rem",
    },
  },
  icon: {
    fontSize: "1.5rem",
  },
};

const ELLIPSIS_STEPS = ["default", "share", "external"];

const EpisodeEllipsisMenu = (props) => {
  const { episode, podcast, mobile, showShare, showEllipsisItems } = props;
  const { styles } = useStyles(baseStyles, props);

  const spotifyExclusive = podcastExclusiveTo(podcast, "spotify");
  const activityContext = useActivityContext();

  const { setListened, setBookmarked } = useRoutinePromises({
    setListened: setListenedPromise,
    setBookmarked: setBookmarkedPromise,
  });

  const { openEpisodeRatingModal } = useActionCreators(ratingActionCreators);

  const { showModal } = useActionCreators(modalActions);

  const openExclusiveModal = useCallback(
    () =>
      showModal("openExclusive", {
        entity: episode,
        entityType: "episode",
        podcast,
      }),
    [showModal, episode, podcast]
  );

  const handleOpenExclusiveModal = useCallback(
    (contentProps, e) => {
      if (contentProps && contentProps.closeMenu) {
        contentProps.closeMenu(e);
      }

      return openExclusiveModal();
    },
    [openExclusiveModal]
  );

  const bookmarked = episode.getIn(["user_data", "bookmarked"]);
  const bookmarkSaving = episode.getIn(["user_data", "bookmark_saving"]);
  const listened = episode.getIn(["user_data", "listened"]);

  const items = useMemo(
    () => showEllipsisItems || ["view", "credits", "share", "external"],
    [showEllipsisItems]
  );

  const analyticsVariables = useMemo(
    () => ({
      ellipsisMenuType: "EpisodeEllipsisMenu",
      episode_id: episode && episode.get("id"),
    }),
    [episode]
  );

  const preventDefault = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleShareClick = useCallback(
    (onChangeStep) => (e) => {
      sendGAEvent({
        podcast_id: podcast?.get("id"),
        podcast_name: podcast?.get("title"),
        entity_type: "episode",
        entity_id: episode?.get("id"),
        entity_name: episode?.get("title"),
        context: "ellipsis menu",
        componentContext: "EpisodeEllipsisMenu",
        ...activityContext,
        action: "shareModalOpen",
      });
      preventDefault(e);
      onChangeStep("share")(e);
    },
    [activityContext, episode, podcast]
  );

  const handleBookmark = useCallback(
    (e) => {
      preventDefault(e);
      return setBookmarked({
        entity: episode,
        entity_type: "episode",
        entity_id: episode.get("id"),
        bookmark: !bookmarked,
        analyticsVariables: {
          // TODO: This was removed in one branch, but not another. May not be needed?
          componentContext: "EpisodeEllipsisMenu",
        },
      });
    },
    [setBookmarked, episode, bookmarked]
  );

  const handleMarkAsListened = useCallback(
    (e) => {
      preventDefault(e);
      setListened({
        entity: episode,
        entity_type: "episode",
        listened: !listened,
        episode_id: episode.get("id"),
        analyticsVariables: {
          // TODO: This was removed in one branch, but not another. May not be needed?
          componentContext: "EpisodeEllipsisMenu",
        },
      });
    },
    [setListened, episode, listened]
  );

  const handleAddTolistClick = useCallback(
    (toggleMenu) => (e) => {
      preventDefault(e);
      showModal("prompt", {
        promptType: "list",
        entity: episode,
        entity_type: "episode",
      });
      toggleMenu();
    },
    [episode, showModal]
  );

  const handleRateClick = useCallback(
    (toggleMenu) => () => {
      toggleMenu();
      openEpisodeRatingModal(episode.get("id"), {
        entity: episode,
      });
    },
    [openEpisodeRatingModal, episode]
  );

  // eslint-disable-next-line react/display-name
  const renderItem = useCallback(
    (contentProps) => (type) => {
      const { toggleMenu, onChangeStep } = contentProps;

      switch (type) {
        case "view":
          return (
            <EllipsisMenuItem
              key="view-episode"
              entity={episode}
              entity_type="episode"
              label="View Episode"
              to={getEpisodeUrl(episode)}
              link
            />
          );
        case "credits":
          return (
            <EllipsisMenuAddCreditsItem
              key="credits"
              entity_type="episode"
              entity={episode}
              toggleMenu={toggleMenu}
            />
          );
        case "share":
          return (
            (mobile || showShare) && (
              <EllipsisMenuItem
                key="share"
                entity={episode}
                entity_type="episode"
                label="Share"
                onClick={handleShareClick(onChangeStep)}
                onMouseDown={preventDefault}
                onMouseUp={preventDefault}
              />
            )
          );
        case "list": // TODO: Actioned
          return (
            <EllipsisMenuItem
              key="list"
              entity={episode}
              entity_type="episode"
              label="Add to List"
              onClick={handleAddTolistClick(toggleMenu)}
              onMouseDown={preventDefault}
              onMouseUp={preventDefault}
            />
          );
        case "mark":
          return (
            <EllipsisMenuItem
              key="mark"
              entity={episode}
              entity_type="episode"
              label={listened ? "Listened" : "Mark As Listened"}
              hoverLabel={
                listened ? "Mark As Not Listened" : "Mark As Listened"
              }
              onClick={handleMarkAsListened}
              onMouseDown={preventDefault}
              onMouseUp={preventDefault}
              actioned={listened}
            />
          );
        case "bookmark": // TODO: What about podcasts?
          return (
            <EllipsisMenuItem
              key="bookmark"
              entity={episode}
              entity_type="episode"
              label={bookmarked ? "Bookmarked" : "Bookmark Episode"}
              hoverLabel={bookmarked ? "Remove Bookmark" : "Bookmark Episode"}
              onClick={handleBookmark}
              onMouseDown={preventDefault}
              onMouseUp={preventDefault}
              submitting={bookmarkSaving}
              actioned={bookmarked}
            />
          );
        case "play":
          return (
            <PlayButton
              key="play"
              entity_type="episode"
              entity_id={episode.get("id")}
              render={(buttonProps) => (
                <EllipsisMenuItem
                  entity={episode}
                  entity_type="episode"
                  label={bookmarked ? "Bookmarked" : "Bookmark Episode"}
                  {...buttonProps}
                />
              )}
              onPlayClick={toggleMenu}
            />
          );
        case "rate":
          return (
            <EllipsisMenuItem
              key="rate"
              entity={episode}
              entity_type="episode"
              label="Rate Episode"
              onClick={handleRateClick(toggleMenu)}
              onMouseDown={preventDefault}
              onMouseUp={preventDefault}
            />
          );
        case "external":
          return (
            <EllipsisMenuItem
              key="external"
              entity={episode}
              entity_type="episode"
              label={
                spotifyExclusive ? "Open Spotify Exclusive" : "Open in app"
              }
              onClick={
                spotifyExclusive
                  ? (e) => handleOpenExclusiveModal(contentProps, e)
                  : onChangeStep("external")
              }
            />
          );
        default:
          return null;
      }
    },
    [
      episode,
      mobile,
      showShare,
      handleAddTolistClick,
      listened,
      handleMarkAsListened,
      bookmarked,
      handleBookmark,
      bookmarkSaving,
      handleRateClick,
      spotifyExclusive,
      handleOpenExclusiveModal,
      handleShareClick,
    ]
  );

  const renderContent = useCallback(
    (contentProps) => {
      const { currentStep, closeMenu, onBack } = contentProps;

      switch (currentStep) {
        case "share":
          return (
            <div key="share" className={css(styles.column)}>
              <EllipsisMenuBackItem {...contentProps} onBack={onBack} />
              <EpisodeSharingLinks
                episode={episode}
                podcast={podcast}
                styles={sharingLinkStyles}
                downshiftProps={contentProps}
              />
            </div>
          );
        case "external":
          return (
            <div key="external" className={css(styles.column)}>
              <EllipsisMenuBackItem
                {...contentProps}
                onBack={onBack}
                padBottom
              />
              <EpisodeExternalPlayerLinks
                podcast={podcast}
                episode={episode}
                onClick={closeMenu}
              />
            </div>
          );
        default:
          return (
            <div key="default" className={css(styles.column)}>
              {items.map(renderItem(contentProps))}
            </div>
          );
      }
    },
    [styles.column, episode, podcast, items, renderItem]
  );

  return (
    <EllipsisMenuButton
      renderContent={renderContent}
      steps={ELLIPSIS_STEPS}
      analyticsVariables={analyticsVariables}
    />
  );
};

EpisodeEllipsisMenu.propTypes = {
  episode: PropTypes.instanceOf(Map).isRequired,
  podcast: PropTypes.instanceOf(Map).isRequired,
  mobile: PropTypes.bool,
  showShare: PropTypes.bool,
  showEllipsisItems: PropTypes.array,
};

EpisodeEllipsisMenu.defaultProps = {
  mobile: false,
  showShare: false,
  showEllipsisItems: null,
};

export default memo(EpisodeEllipsisMenu);
