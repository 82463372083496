import PropTypes from "prop-types";
import { forwardRef, memo, useMemo } from "react";
import { compose } from "redux";

import WhiteBtnWithIcon from "components/Buttons/Items/WhiteBtnWithIconAsync";

import withHover from "utils/react-with-hover";

const AnimatedIconButton = forwardRef(
  (
    {
      onClick,
      hover,
      icon,
      label,
      height,
      width,
      noAnimation,
      iconButtonStyles,
      ...props
    },
    ref
  ) => {
    const labels = useMemo(
      () => ({
        default: hover && !noAnimation ? label : null,
      }),
      [hover, noAnimation, label]
    );

    return (
      <WhiteBtnWithIcon
        onClick={onClick}
        icon={icon}
        styles={iconButtonStyles}
        height={height}
        width={width}
        labels={labels}
        {...props}
        ref={ref}
      />
    );
  }
);

AnimatedIconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  hover: PropTypes.bool,
  icon: PropTypes.object.isRequired,
  label: PropTypes.any,
  height: PropTypes.string,
  width: PropTypes.string,
  noAnimation: PropTypes.bool,
  iconButtonStyles: PropTypes.object,
};

AnimatedIconButton.defaultProps = {
  hover: false,
  label: true,
  height: "2.8rem",
  width: "2.8rem",
  noAnimation: false,
  iconButtonStyles: null,
};

AnimatedIconButton.displayName = "AnimatedIconButton";

export default compose(memo, withHover())(AnimatedIconButton);
