import UAParser from "ua-parser-js";

export default function getDeviceFromUserAgent(ua) {
  const parser = new UAParser();
  parser.setUA(ua);
  const result = parser.getResult();
  switch (result.os.name) {
    case "iOS":
      return "ios";
    case "Android":
      return "android";
    default:
      return "web";
  }
}
