import gStyles from "styles/GenericStyles";

// PC:TRANSLATE
export default {
  container: {
    ...gStyles.contentBox,
    width: "100%",
  },
  thumbnail: {
    position: "relative",
    background: "#e1e1e1",
    boxSizing: "border-box",
  },
  img: {
    position: "absolute",
    display: "block",
    width: "100%",
    height: "100%",
  },
};
