import { faPlay as faPlayRegular } from "@fortawesome/pro-regular-svg-icons/faPlay";
import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons/faCircleNotch";
import { faExclamation } from "@fortawesome/pro-solid-svg-icons/faExclamation";
import { faPause } from "@fortawesome/pro-solid-svg-icons/faPause";
import { faPlay } from "@fortawesome/pro-solid-svg-icons/faPlay";
import * as PropTypes from "prop-types";
import { memo, useCallback, useMemo, useRef } from "react";

import AnimatedIconButton from "components/Buttons/AnimatedIconButton";
import { mainStyles } from "components/Podcast/Items/base-item-styles";

import {
  selectSpecificEpisode,
  selectSpecificPodcast,
} from "selectors/podcast";
import { isMac } from "utils/isAppleDevices";
import { equals } from "utils/misc";
import sendGAEvent from "utils/sendGAEvent";

import useActivityContext from "hooks/useActivityContext";
import useAudioPlayerContext from "hooks/useAudioPlayerContext";
import usePodcastData from "hooks/usePodcastData";
import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";

const buttonStyle = {
  ...mainStyles.playBtnLabel,
  borderRadius: 100,
  border: "none",
  height: "39px",
  width: "100%",
};

const baseStyles = {
  labelWrapper: {
    ...buttonStyle,
  },
  errorButtonLabel: {
    ...buttonStyle,
    backgroundColor: colours.negative,
  },
  errorIconButtonLabel: {
    ...buttonStyle,
    color: colours.negative,
  },
  buttonLabel: {
    fontSize: "1rem",
  },
  labelIcon: {
    marginRight: ".6rem",
  },
};

const iconButtonStyles = {
  padding: 0,
  width: "auto",
  height: "auto",
};

const PlayButton = (props) => {
  const {
    entity_id,
    entity_type,
    filled,
    fullWidth,
    height,
    iconButton,
    iconButtonStyles: passedIconButtonStyles,
    iconSize,
    noAnimation,
    onClick: onClickProp,
    onPlayClick,
    render,
    variation,
    width,
    activityContext,
  } = props;

  const { styles } = useStyles(
    [
      baseStyles,
      {
        labelWrapper: {
          width,
          height,
        },
        errorButtonLabel: {
          width,
          height,
        },
        errorIconButtonLabel: {
          width,
          height,
        },
      },
    ],
    props
  );
  const { isWindowSizeOrLess } = useWindowSize();
  const isMediumOrLess = isWindowSizeOrLess("medium");

  const feedActivityContext = useActivityContext();
  const audioElem = useRef();

  const customStyles = useMemo(
    () => ({
      button: {
        ...(iconButton ? iconButtonStyles : {}),
        width: fullWidth ? "100%" : width || 54,
        height,
      },
    }),
    [width, height, fullWidth, iconButton]
  );

  const {
    playEpisode,
    playerPlay,
    togglePlaying,
    getPlayStateOfEpisode,
    episodePlayingID,
  } = useAudioPlayerContext();

  const { error, episode_id, episode, isPodcast } = useReduxState(
    (state) => {
      const isPodcast = entity_type === "podcast";
      let passedPodcast = null;

      if (isPodcast) {
        passedPodcast = entity_id && selectSpecificPodcast(state, entity_id);
      }

      const podcastEpisodeId = passedPodcast
        ? passedPodcast.getIn(["featured_episode", "id"])
        : null;
      const episode_id = isPodcast ? podcastEpisodeId : entity_id;
      const isSelected = episode_id && equals(episode_id, episodePlayingID);
      let episode = episode_id && selectSpecificEpisode(state, episode_id);
      if (
        !episode &&
        passedPodcast &&
        passedPodcast.hasIn(["featured_episode", "audio_url"])
      ) {
        episode = passedPodcast.get("featured_episode");
      }

      if (isSelected) {
        return {
          error: state.player.getIn(["error"]),
          episode_id,
          episode,
          isPodcast,
        };
      }

      return {
        progress: {},
        episode_id,
        episode,
        isPodcast,
      };
    },
    [entity_type, entity_id, episodePlayingID]
  );

  const podcast = usePodcastData(episode && episode.get("podcast_id"));

  const {
    isPlaying,
    progress: { played, loaded },
  } = getPlayStateOfEpisode(episode_id);

  const listened = useMemo(
    () => episode && episode.getIn(["user_data", "listened"]),
    [episode]
  );

  const preventDefault = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleTogglePlay = useCallback(
    (e) => {
      preventDefault(e);
      sendGAEvent({
        action: "episodePlayButtonClicked",
        episode_id: episode && episode.get("id"),
        podcast_id: episode && episode.get("podcast_id"),
        episode_name: episode && episode.get("title"),
        already_playing: isPlaying,
        episode_progress: played && played.toFixed(2),
        podcast_name: podcast && podcast?.get("title"),
        podcast_category: podcast && podcast?.get("categories")?.toJS(),
        hosting_provider: podcast && podcast?.get("hosting_provider"),
        ...feedActivityContext,
        ...activityContext,
      });

      if (audioElem.current) {
        audioElem.current.play();
      }

      if (onPlayClick) {
        onPlayClick();
      }

      if (isPlaying || played > 0) {
        togglePlaying();
      } else {
        if (playerPlay && episode) {
          playerPlay(episode.get("audio_url"));
        }

        if (episode) {
          playEpisode(episode.get("id"), episode.get("audio_url"), true);
        }
      }
    },
    [
      episode,
      isPlaying,
      played,
      podcast,
      feedActivityContext,
      activityContext,
      onPlayClick,
      togglePlaying,
      playerPlay,
      playEpisode,
    ]
  );

  const {
    icon,
    label,
    title,
    labelStyle,
    onClick,
    onMouseDown,
    onMouseUp,
    iconSpin = false,
  } = useMemo(() => {
    if (error) {
      return {
        icon: faExclamation,
        label: "Error",
        title: error,
        labelStyle: iconButton
          ? styles.errorIconButtonLabel
          : styles.errorButtonLabel,
      };
    }
    if (played === 0 && (isPlaying || loaded > 0)) {
      return {
        icon: faCircleNotch,
        iconSpin: true,
        label: noAnimation ? "Loading Episode" : "Loading",
      };
    }
    if (isPlaying) {
      return {
        icon: faPause,
        label: noAnimation ? "Pause Episode" : "Pause",
        onClick: handleTogglePlay,
        onMouseDown: preventDefault,
        onMouseUp: preventDefault,
      };
    }

    const toPass = {
      icon: filled ? faPlay : faPlayRegular,
      label: listened ? "Played" : "Play",
      onClick: onClickProp || handleTogglePlay,
      onMouseDown: preventDefault,
      onMouseUp: preventDefault,
    };

    if (noAnimation && isPodcast) {
      toPass.label = "Latest Episode";
    }

    return toPass;
  }, [
    error,
    played,
    isPlaying,
    loaded,
    filled,
    noAnimation,
    listened,
    onClickProp,
    handleTogglePlay,
    isPodcast,
    iconButton,
    styles.errorIconButtonLabel,
    styles.errorButtonLabel,
  ]);

  /**
   * Relate to this policy https://webkit.org/blog/6784/new-video-policies-for-ios/
   * We need to inject this virtual audio elem as the real audio is async added to the webpage.
   * So as the user has already "clicked" on an audio it will trigger the episode audio automatically.
   * This way the episode audio will start automatically otherwise it would require two clicks from the user
   *
   * ONLY FOR SAFARI ON MAC
   */
  const audioComponent = <audio autoPlay muted ref={audioElem} />;

  if (render) {
    return (
      <>
        {render({
          icon,
          iconSpin,
          label,
          onClick,
          onMouseDown,
          onMouseUp,
          disabled: !episode,
          title: episode
            ? title || episode.get("title")
            : "No episodes available",
          isPlaying,
          isPlayed: listened,
        })}
        {isMac() && audioComponent}
      </>
    );
  }

  return (
    <>
      <AnimatedIconButton
        icon={icon}
        iconSpin={iconSpin}
        iconSize={iconSize}
        label={label}
        title={title}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseDown}
        labelWrapperStyle={labelStyle || styles.labelWrapper}
        customStyles={customStyles}
        width={isMediumOrLess ? "100%" : "49px"}
        primary={!variation && !iconButton}
        disabled={!episode}
        variation={iconButton ? "transparent" : variation}
        iconButtonStyles={passedIconButtonStyles}
        noAnimation={noAnimation}
        flat
      />
      {isMac() && audioComponent}
    </>
  );
};

PlayButton.propTypes = {
  entity_id: PropTypes.number,
  entity_type: PropTypes.string,
  filled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  height: PropTypes.string,
  iconButton: PropTypes.bool,
  iconButtonStyles: PropTypes.object,
  activityContext: PropTypes.object,
  iconSize: PropTypes.string,
  noAnimation: PropTypes.bool,
  onClick: PropTypes.func,
  onPlayClick: PropTypes.func,
  render: PropTypes.func,
  variation: PropTypes.string,
  width: PropTypes.string,
};

PlayButton.defaultProps = {
  entity_id: null,
  entity_type: null,
  filled: true,
  fullWidth: false,
  height: "39px",
  iconButton: false,
  iconButtonStyles: null,
  iconSize: undefined,
  noAnimation: false,
  onClick: null,
  onPlayClick: null,
  render: null,
  variation: null,
  width: null,
  activityContext: {},
};

export default memo(PlayButton);
